import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import StoreTrafficService from '../../services/StoreTrafficService';
import { REFERRAL_CHOICES, VISITOR_TYPES } from '../../services/StoreTrafficService';
import { addMonths, formatDateTime } from '../../Utils';
import { now } from 'moment';
import StoreTrafficPartyInfoForm from './StoreTrafficPartyInfoForm';


const trafficService = new StoreTrafficService();
const dateUtils = new DateFnsUtils();

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    labelTitle: {
        fontSize: 14,
    },
    table: {
        minWidth: 650,
    },
}));


const defaultOrderDateFilterFromDate = dateUtils.startOfMonth(addMonths(new Date(), -6));
const today = new Date();

export default function StoreTrafficList() {
    const classes = useStyles();
    const [parties, setParties] = useState([]);
    const [partyInfoDialogOpen, setPartyInfoDialogOpen] = useState(false);
    const [currentParty, setCurrentParty] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [bulk, setBulk] = useState(false);

    useEffect(() => {
        loadTrafficEntries(() => setIsLoading(false));
    }, []);

    const loadTrafficEntries = function(successCallback) {
        console.log("loading...");
        trafficService.getStoreTrafficForDate(today)
        .then(function(results) {
            console.log(results);
            const parties = results.map(p => {return {
                ...p
            }});
            setParties(parties);
            successCallback();
        });
    }

    const savePartyInfo = (data, onSuccess, onError) => {
        if (typeof data.id === 'undefined') {
            // new party
            trafficService
              .createPartyInfo(data)
              .then(function(result) {
                console.log("Party Info Created:");
                console.log(result);
                setParties(
                  parties.map(p => (p.id === result.data.id ? result.data : p))
                );
                onSuccess();
              })
              .catch(function(err) {
                console.log("error creating party info");
                onError();
              });

        } else {
            // update party
            let entryToUpdate = findEntry(data.id);
            entryToUpdate = {...entryToUpdate, ...data};
            entryToUpdate['timestamp'] = entryToUpdate['timestamp'] === "" ? null : entryToUpdate['timestamp'];

            console.log("before updating traffic entry:");
            console.log(entryToUpdate);
            /*commissionsService.updateCommission(entryToUpdate)
                .then(function (result) {
                    console.log("Commission Updated:");
                    console.log(result);
                    setCommissions(commissions.map(c => (c.id === result.data.id ? result.data : c)));
                    onSuccess();
                })
            .catch(function(err){
                console.log("error updating commission");
                onError();
            });*/
        }
        
   
    }

    function handlePartyInfoAttrChange(propName, value) {
        const tempParty = {...currentParty};
        tempParty[propName] = value;
        setCurrentParty(tempParty);
    }

    function handleDialogClose() {
        setPartyInfoDialogOpen(false);
        if (currentParty) {
            savePartyInfo(
              currentParty,
              () => {
                "updated entry";
              },
              () => {
                console.log("failed updating traffic entry");
              }
            );
        }
    }

    function findEntry(id) {
        return parties.filter(c => c.id === id)[0];
    }

    function openEntryDialog(currentParty) {
        //let currentEntry = parties.filter((c) => c.id == rowData.id)[0]
        setPartyInfoDialogOpen(true);
    }

    const partyInfoDialog = () => {
      if (!currentParty) return;
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Dialog
            open={partyInfoDialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Party Details</DialogTitle>
            <DialogContent>
              <DialogContentText>Party Details</DialogContentText>
              <StoreTrafficPartyInfoForm
                partyInfo={currentParty}
                handleAttrChange={handlePartyInfoAttrChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDialogClose} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      );
    };

    return (
      <div className="commissions--list">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MaterialTable
            columns={tableColumns()}
            data={parties}
            options={{
              selection: bulk,
              paging: false
            }}
            isLoading={isLoading}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => openEntryDialog(rowData),
                hidden: bulk
              },
              {
                icon: 'add',
                isFreeAction: true,
                onClick: () => {
                    console.log("adding new party info");
                    const party = newParty();
                    setCurrentParty(party);
                    openEntryDialog(currentParty);
                }
              }
            ]}
            title="Store Traffic"
          />
        </MuiPickersUtilsProvider>

        {partyInfoDialog()}

      </div>
    );

}

const tableColumns = () => {
  return [
    {
      title: "Date/Time",
      field: "timestamp",
      render: rowData => formatDateTime(rowData.timestamp),
      editComponent: props => (
        <KeyboardDatePicker
          margin="normal"
          label="Date/Time"
          format="MM/dd/yyyy HH:mm"
          value={props.value}
          onChange={e => props.onChange(e)}
          KeyboardButtonProps={{
            "aria-label": "change visit date"
          }}
        />
      ),
      initialEditValue: () => new Date()
    },
    {
      title: "Type",
      field: "visitor_type"
    },
    {
      title: "Party of",
      field: "count",
      initialEditValue: 1
    },
    {
      title: "Party Info",
      field: "first_name",
      render: rowData => visitorInfo(rowData)
    },
    {
      title: "Assisted By",
      field: "associate_name",
      initialEditValue: "You"
    },
    {
      title: "Referral",
      field: "referred_by"
    },
    {
      title: "Time in Store",
      field: "visit_duration"
    },
    {
      title: "Comments",
      field: "comments"
    }
  ];
};

const visitorInfo = data => {
  return (
    <div>
      {data.first_name} {data.last_name} {data.email}
    </div>
  );
};

const newParty = () => {
    return {
        timestamp: new Date(),
        count: 1,
        visitor_type: 'CUST',
        first_name: 'John',
        last_name: 'Doe',
        email: 'j.doe@gmail.com',
        associate_name: 'Pearl',
        referred_by: 'GOOG',
        visit_duration: '1:00',
        comments: 'in-progress lead'
    };
}