import axios from 'axios';
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;
const BASE = `${API_URL}/api/traffic/`;

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true;

class StoreTrafficService {
    dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

    getStoreTrafficForDate(date) {
        let dateFrom = moment(date).hours(0).minutes(0).seconds(0)
        let dateTo = moment(dateFrom).hours(23).minutes(59).seconds(59);
        const params = {
            'timestamp_range_from': moment(dateFrom).format(this.dateTimeFormat),
            'timestamp_range_to': moment(dateTo).format(this.dateTimeFormat),
            'limit': '10000',
            'offset': '0'
        };
        const requestConfig = {
            params: {
                ...params
            },
        };
        return axios.get(BASE, requestConfig).then(response => response.data.results);
    }

    getFurnitaliaInfo() {
        return axios.get(`${API_URL}/api/client/`)
            .then(res => {
                return res.data.data;
            });
    }

    createPartyInfo(party) {
        let body = JSON.stringify(party);
        return axios.post(BASE, body);
    }

    updatePartyInfo(party) {
        let body = JSON.stringify(party);
        return axios.put(BASE + party.pk + "/", body);
    }
};


export const VISITOR_TYPES = [{
        "value": 'walkin',
        "label": 'General Walk-in'
    },
    {
        "value": 'customer',
        "label": 'Returning Customer'
    },
    {
        "value": 'lead',
        "label": 'In-progress Lead'
    },
    {
        "value": 'other',
        "label": 'Other (see comments)'
    }
];

export const REFERRAL_CHOICES = [{
            "value": 'RVL',
            "label": 'Roseville Store'
        },
        {
            "value": 'RET',
            "label": 'Returning Customer'
        },
        {
            "value": 'COP',
            "label": 'Copenhagen Customer'
        },
        {
            "value": 'GOOG',
            "label": 'Google Search'
        },
        {
            "value": 'EBL',
            "label": 'E-Blast'
        },
        {
            "value": 'VENDOR',
            "label": 'Vendor Website Referral'
        },
        {
            "value": 'TV',
            "label": 'TV'
        },
        {
            "value": 'MAG',
            "label": 'Magazine'
        },
        {
            "value": 'REF',
            "label": 'Referred by Fiends/relatives'
        },
        {
            "value": 'SOC',
            "label": 'Social networks'
        },
        {
            "value": 'BILL',
            "label": 'Billboard/Sign/Drive-by'
        },
        {
            "value": 'CST',
            "label": 'Referred by Existing Furnitalia customer'
        },
        {
            "value": 'NWP',
            "label": 'Newspaper'
        },
        {
            "value": 'EXPO',
            "label": 'Home & Landscape Show'
        },
        {
            "value": 'NO',
            "label": 'Not Referred'
        },
        {
            "value": 'UNK',
            "label": 'Unknown/could Not find out'
        },
        {
            "value": 'OTH',
            "label": 'Other (see Comments)'
        },
];

export default StoreTrafficService;