import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  REFERRAL_CHOICES,
  VISITOR_TYPES
} from "../../services/StoreTrafficService";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline"
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    margin: theme.spacing(2)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export default function StoreTrafficPartyInfoForm(props) {
  const classes = useStyles();
  const { partyInfo, handleAttrChange } = props;
  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.formControl}>
        <KeyboardDatePicker
          margin="normal"
          label="Date/Time"
          format="MM/dd/yyyy HH:mm"
          value={partyInfo.timestamp}
          onChange={value => handleAttrChange("timestamp", value)}
          KeyboardButtonProps={{
            "aria-label": "select party walking time"
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="party-of"
          label="Part Of"
          className={classes.textField}
          value={partyInfo.count}
          onChange={e => handleAttrChange("count", e.target.value)}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="status">Visitor Type</InputLabel>
        <Select
          value={partyInfo.visitor_type}
          onChange={e => handleAttrChange("visitor_type", e.target.value)}
          inputProps={{
            name: "visitory_type",
            id: "visitor-type"
          }}>
          {VISITOR_TYPES.map(type => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="first-name"
          label="First Name"
          className={classes.textField}
          value={partyInfo.first_name}
          onChange={e => handleAttrChange("first_name", e.target.value)}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="last-name"
          label="Last Name"
          className={classes.textField}
          value={partyInfo.last_name}
          onChange={e => handleAttrChange("last_name", e.target.value)}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="email"
          label="Email"
          className={classes.textField}
          value={partyInfo.email}
          onChange={e => handleAttrChange("email", e.target.value)}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="associate"
          label="Assisted By"
          className={classes.textField}
          value={partyInfo.associate_name}
          onChange={e => handleAttrChange("associate", e.target.value)}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="status">Referred By</InputLabel>
        <Select
          value={partyInfo.referred_by}
          onChange={e => handleAttrChange("referred_by", e.target.value)}
          inputProps={{
            name: "referred_by",
            id: "referred_by"
          }}>
          {REFERRAL_CHOICES.map(referral => (
            <MenuItem key={referral.value} value={referral.value}>
              {referral.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="comment"
          label="Comments"
          className={classes.textField}
          value={partyInfo.comments}
          onChange={e => handleAttrChange("comments", e.target.value)}
          margin="normal"
        />
      </FormControl>
    </form>
  );
}
