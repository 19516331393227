import React, { useEffect, useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaterialTable from "material-table";
import Typography from '@material-ui/core/Typography';
import CommissionsFilterForm from './CommissionFilterForm';
import CommissionsService from '../../services/CommissionsService';
import {formatDate, currencyFormat, nvl, addMonths, useStateWithLocalStorage} from '../../Utils';

const commissionsService = new CommissionsService();
const dateUtils = new DateFnsUtils();

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    labelTitle: {
        fontSize: 14,
    },
    table: {
        minWidth: 650,
    },
}));

const defaultOrderDateFilterFromDate = dateUtils.startOfMonth(addMonths(new Date(), -6));

export default function CommissionsList() {
    const classes = useStyles();
    const [commissions, setCommissions] = useState([]);
    const [nextPageURL, setNextPageURL] = useState('');
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [currentCommission, setCurrentCommission] = useState();
    const [bulk, setBulk] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [filterStatus, setFilterStatus] = useStateWithLocalStorage('furnicloudCommissionsFiltersStatus');
    const [filterOrderNumber, setFilterOrderNumber] = useStateWithLocalStorage('furnicloudCommissionsFiltersOrderNumber');
    const [filterOrderDateFrom, setFilterOrderDateFrom] = useStateWithLocalStorage('furnicloudCommissionsFiltersDateFrom', defaultOrderDateFilterFromDate, a => new Date(a));
    const [filterOrderDateTo, setFilterOrderDateTo] = useStateWithLocalStorage('furnicloudCommissionsFiltersDateTo', new Date(), a => new Date(a));
    const [filterAssociateId, setFilterAssociateId] = useStateWithLocalStorage('furnicloudCommissionsFiltersAssociateId');

    useEffect(() => {
        loadCommissions(() => setIsLoading(false));
    }, []);

    const loadCommissions = function(successCallback) {
        console.log("loading...");
        const options = {};
        if (filterOrderDateFrom) {
            console.log("from=");
            let from = new Date(filterOrderDateFrom);
            options['order_date_from'] = dateUtils.format(from, "yyyy-MM-dd");
        }
        if (filterOrderDateTo){    
            let to = new Date(filterOrderDateTo);
            options['order_date_to'] = dateUtils.format(to, "yyyy-MM-dd");
        }
        if (filterAssociateId){    
            options['associate'] = filterAssociateId;
        }
        if (filterStatus) {
            options['status'] = filterStatus;
        }
        if (filterOrderNumber) {
            options['order_number'] = filterOrderNumber;
        }
        commissionsService.getCommissions(options)
        .then(function(result) {
            console.log(result.results);
            const commissions = result.results.map(c => {return {
                ...c,
                'amount': nvl(c.amount),
                'comments': nvl(c.comments),
                'status': nvl(c.status),
                'paid_date': nvl(c.paid_date)
            }});
            setCommissions(commissions);
            successCallback();
        });
    };

    const updateCommission = (data, onSuccess, onError) => {
        let commissionToUpdate = findCommission(data.id);
        commissionToUpdate = {...commissionToUpdate, ...data};
        commissionToUpdate['paid_date'] = commissionToUpdate['paid_date'] === "" ? null : commissionToUpdate['paid_date'];

        console.log("before updating commission:");
        console.log(commissionToUpdate);
        commissionsService.updateCommission(commissionToUpdate)
        .then(function(result) {
            console.log("Commission Updated:");
            console.log(result);        
            setCommissions(commissions.map(c => (c.id === result.data.id ? result.data : c)));
            onSuccess();            
        })
        .catch(function(err){
            console.log("error updating commission");
            onError();
        });
    };

    function nextPage(){
        commissionsService.getCommissionsByURL(this.state.nextPageURL).then((result) => {
            setCommissions(result.data);
            setNextPageURL(result.nextlink);
        });
    }

    function handleClose() {
        setCommentDialogOpen(false);
        if (currentCommission) {
            updateCommission(currentCommission, ()=>{"updated comment"}, () =>{console.log("failed updating commission comment")});
        }
    }

    function findCommission(id) {
        return commissions.filter(c => c.id === id)[0];
    }

    function findCommissionIndexById(id) {
        return commissions.findIndex(c => c.id === id);
    }

    function openCommentsDialog(rowData) {
        let currentCommission = commissions.filter((c) => c.id == rowData.id)[0]
        setCommentDialogOpen(true);
        setCurrentCommission(currentCommission);
    }

    function setCommissionComment(commentText) {
        setCurrentCommission({...currentCommission, comments: commentText});
    }

    function markCommissionsAsPaid(event, data) {
        console.log(data);
        setIsLoading(true);
        if (Array.isArray(data)) {
            // mark as paid bulk
            const commissionsToUpdate = [];
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const commission = data[key];
                    commissionsToUpdate.push(commission);
                }
            }                                
            Promise.all(commissionsToUpdate.map(c => {
                let commissionToUpdate = findCommission(c.id);
                commissionToUpdate = {
                    ...commissionToUpdate, 
                    status:"PAID", 
                    paid_date: new Date().toISOString()
                };
                return new Promise((resolve, reject) => {
                    updateCommission(commissionToUpdate, resolve, reject);
                });
            }))
            .then(function(updated){
                loadCommissions(() => setIsLoading(false));
                
            })
            .catch(function(err){
                console.log("error marking commissions as paid");
                alert("error marking commissions as paid");
                setIsLoading(false);
            });

        } else {
            // mark single as paid
            setIsLoading(true);
            let commissionToUpdate = findCommission(data.id);
            commissionToUpdate = {
                ...commissionToUpdate, 
                status:"PAID", 
                paid_date: new Date().toISOString()
            };
            // console.log("before updating commission:");
            // console.log(commissionToUpdate);
            updateCommission(commissionToUpdate, () => {console.log("success"); setIsLoading(false);}, () => {console.log("error"); setIsLoading(false);});
        }
    }

    const editCommentDialog = () => {
        return (
            <Dialog open={commentDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a comment for the commission.
                    </DialogContentText>
                    <TextField
                        id="comment"
                        label="Comments"
                        multiline
                        rows="4"
                        onChange={event => setCommissionComment(event.target.value)}
                        margin="normal"
                        value={currentCommission.comments}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    Save
                </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const subtotalsTable = () => {
        const subtotals = calculateCommissionSubtotals(commissions);
        return (
            <Paper className={classes.paper}>
              <Table className={classes.table} aria-label="caption table">
                <caption>Subtotals are shown for commissions falling within selected date range only</caption>
                <TableHead>
                  <TableRow>
                    <TableCell>Commissions Subtotals</TableCell>
                    <TableCell align="right">Subtotal</TableCell>                    
                    <TableCell align="right">Grand Total</TableCell>                    
                    <TableCell align="right">Sale Portion</TableCell>                    
                    <TableCell align="right">Commissions Claimed</TableCell>                    
                    <TableCell align="right">Check: 2.5% of Sale Portion</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(subtotals).map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{currencyFormat(row.subtotal)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.grand_total)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.sale_portion)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.commissions_due)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.sale_portion * 0.025)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
        );
    }

    return (
        <div className="commissions--list">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <CommissionsFilterForm 
                classes = {classes}
                filterOrderDateFrom = {filterOrderDateFrom}
                updateFilterOrderDateFrom = {setFilterOrderDateFrom}
                filterOrderDateTo = {filterOrderDateTo}
                updateFilterOrderDateTo = {setFilterOrderDateTo}
                filterAssociateId = {filterAssociateId}
                updateFilterAssociateId= {setFilterAssociateId}
                filterStatus = {filterStatus}
                onFilterStatusUpdate = {setFilterStatus}
                filterOrderNumber = {filterOrderNumber}
                onFilterOrderNumberUpdate = {setFilterOrderNumber}
                onFilter= {() =>{
                    setIsLoading(true);
                    loadCommissions(() => setIsLoading(false));
                }}
            />
            
            <MaterialTable
                columns={tableColumns()}
                data={commissions}
                detailPanel={rowData => {
                    return (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <a href="#" target="_blank">View {rowData.order_number}</a>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <Typography className={classes.labelTitle} color="textSecondary" gutterBottom>
                                            Comments:
                                        </Typography>
                                        {rowData.comments}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                    selection: bulk,
                    paging: false
                }}
                isLoading={isLoading}
                actions = {[
                    {
                        icon: 'comment',
                        tooltip: 'Add Comment',
                        onClick: (event, rowData) => openCommentsDialog(rowData),
                        hidden: bulk
                    },
                    {
                        icon: 'attach_money',
                        
                        tooltip: 'Mark as Paid',
                        onClick: (event, data) => {
                            markCommissionsAsPaid(event, data);
                        }
                    },
                    {
                        tooltip: 'Bulk',
                        icon: 'check',
                        isFreeAction: true,
                        onClick: (evt, data) => {setBulk(!bulk)}
                    }
                ]}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                          updateCommission(newData, resolve, reject);
                      }),
                  }}
                title="Commissions"
            />

            { commissions && subtotalsTable() }

            </MuiPickersUtilsProvider>

            { currentCommission && editCommentDialog() }

        </div>
    );

}

const tableColumns = () => {
    return [
            { title: "Associate Name", field: "associate_name", editable: "never" },
            { title: "Order #", field: "order_number", editable: "never", render: rowData => formatOrderNumber(rowData)  },
            { title: "Order Date", field: "order_date", editable: "never", render: rowData => formatDate(rowData.order_date) },
            { title: "Status", field: "order_status", editable: "never", render: rowData => formatOrderStatus(rowData) },
            { title: "Subtotal", field: "order_subtotal", editable: "never", type: "numeric", render: rowData => currencyFormat(rowData.order_subtotal) },
            { title: "Grand Total", field: "order_grand_total", editable: "never", type: "numeric", render: rowData => currencyFormat(rowData.order_grand_total) },
            { title: "Due", field: "order_balance_due", editable: "never", type: "numeric", render: rowData => currencyFormat(rowData.order_balance_due) },
            { title: "Sale Portion", field: "sale_portion", editable: "never", type: "numeric", render: rowData => currencyFormat(rowData.sale_portion) },
            { title: "Commission", field: "amount", type: "numeric", render: rowData => currencyFormat(rowData.amount) },
            { title: "Commission Status", field: "status", lookup: { "NEW": "Unclaimed", "INELIGIBLE": "Ineligible", "PAID": "Paid", "PENDING": "Claimed" } },
            { title: "Paid Date", field: "paid_date", 
                render: rowData => formatDate(rowData.paid_date),
                editComponent: props => (
                    <KeyboardDatePicker
                        margin="normal"
                        label="Paid Date"
                        format="MM/dd/yyyy"
                        value={props.value}
                        onChange={e => props.onChange(e)}
                        KeyboardButtonProps={{
                            'aria-label': 'change paid date',
                        }}
                    />
                  )
            },
        ];
};

function formatOrderStatus(commission) {
    let delivered_date = commission.order_status === "Delivered" 
        ? " on " + formatDate(commission.delivered_date) 
        : "";
    return commission.order_status + delivered_date;
}

function formatOrderNumber(commission) {
    return commission.order_number + " \n(" + commission.customer + ")";
}

function calculateCommissionSubtotals(commissions) {
    const subtotals = {};
    commissions.map( c => {
        if (c.associate_name in subtotals) {
            subtotals[c.associate_name].subtotal += nvl(c.order_subtotal, 0.0);
            subtotals[c.associate_name].grand_total += nvl(c.order_grand_total, 0.0);
            subtotals[c.associate_name].sale_portion += nvl(c.sale_portion, 0.0);
            subtotals[c.associate_name].commissions_due += nvl(c.amount, 0.0);
        } else {
            subtotals[c.associate_name]  = {
                'name': c.associate_name,
                'subtotal': nvl(c.order_subtotal, 0.0),
                'grand_total': nvl(c.order_grand_total, 0.0),
                'sale_portion': nvl(c.sale_portion, 0.0),
                'commissions_due': nvl(c.amount, 0.0)
            }
        }
    });
    return subtotals;
}