import React from 'react';
import './App.css';
import CommissionsList from './components/commissions/CommissionsList';

function CommissionsApp() {
  return (
    <div className="App">
      <CommissionsList />
    </div>
  );
}

export default CommissionsApp;
