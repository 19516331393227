import React from "react";

const dateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Los_Angeles'
  };

export function formatDate(dateVal) {
    if (dateVal === null || dateVal === "") {
        return "";
    }

    let d = new Date(dateVal);
    var datestring = (d.getMonth()+1).toString().padStart(2, '0')  + "/" + d.getDate().toString().padStart(2, '0') + "/" + d.getFullYear();
    return datestring;
}

export function formatDateTime(dateVal) {
    if (dateVal === null || dateVal === "") {
        return "";
    }

    return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions).format(new Date(dateVal));
}

export function currencyFormat(numericValue) {
    return "$" + parseFloat(Math.round(numericValue * 100) / 100).toFixed(2);
}

export function nvl(value, defaultValue="") {
    return (typeof(value) === "undefined" || value === null) ? defaultValue : value;
}

export function addMonths(date, months) {
    var month = (date.getMonth() + months) % 12;
    //create a new Date object that gets the last day of the desired month
    var last = new Date(date.getFullYear(), month + 1, 0);

    //compare dates and set appropriately
    if (date.getDate() <= last.getDate()) {
        date.setMonth(month);
    }

    else {
        date.setMonth(month, last.getDate());
    }

    return date;
}

export const useStateWithLocalStorage = (localStorageKey, defaultValue, unserializeFn) => {
    if (typeof unserializeFn !== 'function') {
        //identity function
        unserializeFn = a => a; 
    }

    let initial = localStorage.getItem(localStorageKey);
    // console.log(localStorageKey);
    // console.log(initial);
    if (initial && initial !== 'undefined') {
        initial = unserializeFn(JSON.parse(initial));
    } else {
        initial = null;
    }
    const [value, setValue] = React.useState(
      initial || defaultValue
    );
    React.useEffect(() => {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value]);
    return [value, setValue];
  };