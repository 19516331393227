import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

class CommissionsService {

    getCommissions(options) {
        const url = `${API_URL}/api/commissions/`;
        const config = {
            params: {
                ...options
            },
        };
        return axios.get(url, config).then(response => response.data);
    }  
    getCommissionsByURL(link){
        const url = `${API_URL}${link}`;
        return axios.get(url).then(response => response.data.results);
    }
    getCommission(id) {
        const url = `${API_URL}/api/commissions/${id}`;
        return axios.get(url).then(response => response.data);
    }
    // deleteCustomer(customer){
    //     const url = `${API_URL}/api/customers/${customer.pk}`;
    //     return axios.delete(url);
    // }
    // createCustomer(customer){
    //     const url = `${API_URL}/api/customers/`;
    //     return axios.post(url,customer);
    // }
    updateCommission(commission){
        const url = `${API_URL}/api/commissions/${commission.id}/`;
        return axios.put(url, commission);
    }
}


export const ASSOCIATE_LIST = [
    {
        "associateId": 2,
        "associateName": "Dmitriy"
    },
    {
        "associateId": 16,
        "associateName": "Greg"
    },
    {
        "associateId": 18,
        "associateName": "Becca"
    },
    {
        "associateId": 31,
        "associateName": "Ted"
    },
    {
        "associateId": 35,
        "associateName": "Val"
    },
    {
        "associateId": 36,
        "associateName": "Tracie"
    }
];

export const COMMISSION_STATUSES = [
    {
        "value": "NEW",
        "label": "Unclaimed"
    },
    {
        "value": "PENDING",
        "label": "Claimed"
    },
    {
        "value": "INELIGIBLE",
        "label": "Ineligible/Rejected"
    },
    {
        "value": "PAID",
        "label": "Paid"
    }
];

export default CommissionsService;
