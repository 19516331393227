import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {ASSOCIATE_LIST, COMMISSION_STATUSES} from '../../services/CommissionsService';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        margin: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function CommissionFilterForm(props) {
    const classes = useStyles();
    return (
        <div className="commissions-filter--form">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form className={classes.root} autoComplete="off">
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="associate">Associate</InputLabel>
                            <Select
                                value={props.filterAssociateId}
                                onChange={(e) => props.updateFilterAssociateId(e.target.value)}
                                inputProps={{
                                    name: 'associate',
                                    id: 'associate-name-filter',
                                }}
                            >
                                <MenuItem value="">ALL</MenuItem>    
                                {ASSOCIATE_LIST.map(associate => (
                                    <MenuItem key={associate.associateId} 
                                        value={associate.associateId}>{associate.associateName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="status">Status</InputLabel>
                            <Select
                                value={props.filterStatus}
                                onChange={(e) => props.onFilterStatusUpdate(e.target.value)}
                                inputProps={{
                                    name: 'status',
                                    id: 'status-filter',
                                }}
                            >
                                <MenuItem value="">ALL</MenuItem>    
                                {COMMISSION_STATUSES.map(status => (
                                    <MenuItem key={status.value} 
                                        value={status.value}>{status.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Order Date From"
                                format="MM/dd/yyyy"
                                value={props.filterOrderDateFrom}
                                onChange={(date) => props.updateFilterOrderDateFrom(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change order from date',
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="To"
                                format="MM/dd/yyyy"
                                value={props.filterOrderDateTo}
                                onChange={(date) => props.updateFilterOrderDateTo(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change order to date',
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="filter-order-number"
                                label="Order #"
                                className={classes.textField}
                                value={props.filterOrderNumber}
                                onChange={(e) => props.onFilterOrderNumberUpdate(e.target.value)}
                                margin="normal"
                            />
                        </FormControl>
                        <Button 
                            variant="outlined" 
                            onClick={props.onFilter}
                        >
                            Filter
                        </Button>
                </form>
            </MuiPickersUtilsProvider>
        </div>
    );
}